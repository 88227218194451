import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "@atlaskit/spinner";
import { setStatus, uploadUsers } from "../../slices/user";
import featuredIcon from "../../assets/icons/featuredIcon.svg";
import uploadIcon from "../../assets/icons/Icon.svg";
import deleteIcon from "../../assets/icons/rubbish.png";
import Button from "../../components/Button";
import "./_employee.scss";

const MuiFileUploader = ({ handleClose, id }) => {
  const { uploadStatus } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [notice, setNotice] = useState({
    text: "",
    state: "",
  });

  const [file, setFile] = useState(null);
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
    e.target.value = "";
  };
  const handleReset = () => {
    setFile(null);
    setNotice({ text: "", state: "" });
    dispatch(setStatus("idle"));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(uploadUsers(file)).then((res) => {
      if (res?.error) {
        setNotice({
          text: res?.error?.message
            ? res?.error?.message
            : "Somthing went wrong !",
          state: "error",
        });
      } else {
        if (res?.payload) {
          setNotice({
            text: res?.payload.message.message,
            state: "success",
          });

          setTimeout(() => {
            handleClose(id);
          }, 1500);
        }
      }
    });
  };
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setFile(e.dataTransfer.files[0]);
    } else if (e.type === "dragleave") {
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      var mimeType = e.dataTransfer.files[0].type;
      if (mimeType !== "text/csv") {
        setNotice({
          text: "Please select CSV file",
          state: "error",
        });
        dispatch(setStatus("failed"));
      }
      setFile(e.dataTransfer.files[0]);
    }
  };
  return (
    <>
      <form
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={handleSubmit}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        accept="text/csv"
      >
        <div className="file-upload-contain">
          <input
            multiple={false}
            type="file"
            id="input-file-upload"
            onChange={handleOnChange}
            name="file"
            accept=".csv"
          />
        </div>

        <label id="label-file-upload" htmlFor="input-file-upload">
          <img src={featuredIcon} alt="img" />
          <div>
            <span>Click to upload </span>or drag and drop CSV FILE
          </div>
          <div className="status-wrapper">
            {notice.state === "error" ? (
              <p>{notice.text}</p>
            ) : (
              <span>{notice.text}</span>
            )}
          </div>
        </label>

        {file === undefined || file === null ? (
          <div></div>
        ) : (
          <div className="small_box_wrraper">
            <div className="small_box">
              <div className="img1">
                <img src={uploadIcon} alt="img" />
                <p> {file != null && file?.name}</p>
                <p>{file != null && `(${file?.size} bytes)`}</p>
              </div>
              <div className="img2" onClick={() => handleReset()}>
                <img src={deleteIcon} alt="delete-icon" />
              </div>
            </div>
            <Button
              type="submit"
              text={
                uploadStatus === "failed" ? (
                  "ERROR"
                ) : uploadStatus === "loading" ? (
                  <Spinner interactionName="load" />
                ) : (
                  "Submit"
                )
              }
              className={
                uploadStatus === "failed"
                  ? "disabled-input submit-button"
                  : "submit-button"
              }
            />
          </div>
        )}
      </form>
    </>
  );
};

export default MuiFileUploader;
