import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import MuiFileUploader from "./uploadFileDetails";
const UploadFileModal = ({
  title,
  className,
  id,
  open,
  handleClose,
  data,
  isEmployee,
  ...rest
}) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason == "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal "
    >
      <DialogTitle>
        <span className="title-modals"> {title} </span>
        <div className="close_button">
          <CloseIcon onClick={() => handleClose(id)} />
        </div>
      </DialogTitle>
        <DialogContent>
          <MuiFileUploader handleClose={handleClose} id={id} />
        </DialogContent>
    </Dialog>
  );
};

export default UploadFileModal;
